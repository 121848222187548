import React, { useState } from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar.js";
import FooterContainer from "../../components/Footer/FooterContainer.jsx";
import Wrapper from "../../styles/Wrapper.js";
import MainHeading from "../../styles/MainHeading.js";
import Button from "../../styles/Button.js";
import {
  PlansContainer,
  PlanCard,
  PlanDetails,
  PlanTitle,
  PlanPrice,
  PlanFeature,
  PlanPopularBadge,
  PlanComparison,
  Tooltip,
} from "./SubscriptionPlanSelectionStyles.js";

const plans = [
  {
    title: "Basic Plan",
    price: "FREE!",
    features: ["Ad-supported experience", "Access to basic content"],
    popular: false,
    backgroundColor: "linear-gradient(135deg, #00ffe5 0%, #000dff 100%)", // Example background color
  },
  {
    title: "Standard Plan",
    price: "$4.99 / month",
    features: ["Ad-free experience", "Access to exclusive content"],
    popular: true,
    backgroundColor: "linear-gradient(135deg, #ff5e00 0%, #00ff0d 100%)", // Example background color
  },
  {
    title: "Premium Plan",
    price: "$7.99 / month",
    features: ["Ad-free experience", "Access to exclusive content", "Priority support", "Early access to new features"],
    popular: false,
    backgroundColor: "linear-gradient(135deg, #ffea00 0%, #ff0000 100%)", // Example background color
  },
];

function SubscriptionPlanSelection() {
  const [selectedPlan, setSelectedPlan] = useState(null);

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  return (
    <>
      <Navbar />
      <Wrapper py="2rem">
        <MainHeading>Select Your Subscription Plan</MainHeading>
        <PlansContainer>
          {plans.map((plan, index) => (
            <PlanCard
              key={index}
              onClick={() => handlePlanSelect(plan)}
              selected={selectedPlan === plan}
              backgroundColor={plan.backgroundColor}
            >
              {plan.popular && <PlanPopularBadge>Most Popular</PlanPopularBadge>}
              <PlanTitle>{plan.title}</PlanTitle>
              <PlanPrice>{plan.price}</PlanPrice>
              <PlanDetails>
                {plan.features.map((feature, i) => (
                  <PlanFeature key={i}>{feature}</PlanFeature>
                ))}
              </PlanDetails>
              <Tooltip>
                Click to select this plan
              </Tooltip>
            </PlanCard>
          ))}
        </PlansContainer>
          <PlanComparison>
            <table>
              <thead>
                <tr>
                  <th>Feature</th>
                  {plans.map((plan, index) => (
                    <th key={index}>{plan.title}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Price</td>
                  {plans.map((plan, index) => (
                    <td key={index}>{plan.price}</td>
                  ))}
                </tr>
                <tr>
                  <td>Ad-free experience</td>
                  {plans.map((plan, index) => (
                    <td key={index}>{plan.features.includes("Ad-free experience") ? "✔️" : "❌"}</td>
                  ))}
                </tr>
                <tr>
                  <td>Access to exclusive content</td>
                  {plans.map((plan, index) => (
                    <td key={index}>{plan.features.includes("Access to exclusive content") ? "✔️" : "❌"}</td>
                  ))}
                </tr>
                <tr>
                  <td>Priority support</td>
                  {plans.map((plan, index) => (
                    <td key={index}>{plan.features.includes("Priority support") ? "✔️" : "❌"}</td>
                  ))}
                </tr>
                <tr>
                  <td>Early access to new features</td>
                  {plans.map((plan, index) => (
                    <td key={index}>{plan.features.includes("Early access to new features") ? "✔️" : "❌"}</td>
                  ))}
                </tr>
              </tbody>
            </table>
          </PlanComparison>
        {selectedPlan && (
          <Link to={{
            pathname: "/account",
            state: { selectedPlan } // Pass the selected plan to the next page
          }}>
            <Button>Proceed with {selectedPlan.title}</Button>
          </Link>
        )}
      </Wrapper>
      <FooterContainer />
    </>
  );
}

export default SubscriptionPlanSelection;
