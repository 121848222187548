import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar.js";
import FooterContainer from "../../components/Footer/FooterContainer.jsx";
import Wrapper from "../../styles/Wrapper.js";
import MainHeading from "../../styles/MainHeading.js";
import Button from "../../styles/Button.js";

function TryPremium() {
	return (
		<>
			<Navbar />
			<Wrapper py="2rem">
				<MainHeading>Go Premium</MainHeading>
				<p>Enjoy exclusive features and premium content by subscribing to our premium plan.</p>
				<Link to="/subscribe">
					<Button>Subscribe Now</Button>
				</Link>
			</Wrapper>
			<FooterContainer />
		</>
	);
}

export default TryPremium;
