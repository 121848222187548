import styled from "styled-components";
import { colors } from "../../styles/variables.js";

export const AccountInfoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  background: ${colors.cardBg};
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 0 auto;
`;

export const AccountInfoForm = styled.form`
  width: 100%;
`;

export const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const FormLabel = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: ${colors.text};
`;

export const FormInput = styled.input`
  width: 100%;
  padding: 0.75rem;
  border: 1px solid ${colors.border};
  border-radius: 5px;
  font-size: 1rem;
  color: ${colors.text};
  background: ${colors.inputBg};

  &:focus {
    border-color: ${colors.primary};
    outline: none;
  }
`;
