import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar.js";
import FooterContainer from "../../components/Footer/FooterContainer.jsx";
import Wrapper from "../../styles/Wrapper.js";
import MainHeading from "../../styles/MainHeading.js";
import styled from "styled-components";
import Button from "../../styles/Button.js";

const InfoContainer = styled.div`
  padding: 2rem;
  background: #0b0d47;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 2rem auto;
  text-align: center;
`;

const InfoItem = styled.p`
  margin: 1rem 0;
  font-size: 1.2rem;
  color: #d1c326;
`;

function BillingInformation() {
  const location = useLocation();
  const history = useHistory();
  const { selectedPlan, accountInfo } = location.state;

  const handleProceedToPayment = () => {
    history.push("/payment", { selectedPlan, accountInfo });
  };

  return (
    <>
      <Navbar />
      <Wrapper py="2rem">
        <MainHeading>Billing Information</MainHeading>
        <InfoContainer>
          
          <InfoItem><strong>First Name:</strong> {accountInfo.firstName}</InfoItem>
          <InfoItem><strong>Last Name:</strong> {accountInfo.lastName}</InfoItem>
          <InfoItem><strong>Email:</strong> {accountInfo.email}</InfoItem>
          <InfoItem><strong>Selected Plan:</strong> {selectedPlan.title}</InfoItem>
          <InfoItem><strong>Price:</strong> {selectedPlan.price}</InfoItem>
        </InfoContainer>
        <Button onClick={handleProceedToPayment}>Proceed to Payment</Button>
      </Wrapper>
      <FooterContainer />
    </>
  );
}

export default BillingInformation;
