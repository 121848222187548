import styled from "styled-components";
import { colors } from "../../../styles/variables.js";

export const GetStartedCards = styled.div`
	display: flex;
	justify-content: space-evenly;
	flex-wrap: wrap;
	margin-top: 2.5rem;
	margin-bottom: 1rem;
`;

export const GetStartedCard = styled.div`
	width: 13rem;
	text-align: center;

	@media (max-width: 700px) {
		margin: 1.5rem 0;
	}

	.icon {
		font-size: 6rem;
		color: #f7d200;
	}

	h1 {
		font-size: 2.2rem;
		margin: 0.9rem 0;
		color: #f78800;
	}

	h3 {
		font-weight: 600;
		font-size: 1.4rem;
		color: black;
		margin: 1rem;
		background-color: #ffffff;
	}

	p {
		font-size: 1rem;
		font-weight: 400;
		line-height: 1.4;
		color: #ffffff;
		background-color: #3b3b33;
	}
`;
