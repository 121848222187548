import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import bImg from "../../../images/bg.jpg";
import { Div, IndexGrid, IndexText, IndexButtons, IndexButton } from "./IndexHeaderStyles.js"; // Styled components

// Media queries for responsiveness
const responsiveStyles = {
  heading: {
    fontSize: "2.5rem", // Default size for large screens
    "@media(max-width: 768px)": {
      fontSize: "2rem", // Tablet
    },
    "@media(max-width: 576px)": {
      fontSize: "1.5rem", // Mobile
    },
  },
  paragraph: {
    fontSize: "1.2rem", // Default size
    "@media(max-width: 768px)": {
      fontSize: "1rem", // Tablet
    },
    "@media(max-width: 576px)": {
      fontSize: "0.9rem", // Mobile
    },
  },
  button: {
    padding: "1rem 2rem",
    "@media(max-width: 768px)": {
      padding: "0.8rem 1.5rem", // Smaller padding for tablets
    },
    "@media(max-width: 576px)": {
      padding: "0.6rem 1rem", // Even smaller padding for mobile
    },
  },
  backgroundImage: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    "@media(max-width: 768px)": {
      backgroundAttachment: "scroll", // To prevent issues on mobile
    },
  },
};

function IndexHeaderContainer() {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const history = useHistory();

  const handleWriteFiction = () => {
    if (!isAuthenticated) {
      return toast.info("Login Required!");
    }
    history.push("/create-fiction");
  };

  const handleExploreFictions = () => {
    history.push(`/categories/Popular Fictions`);
  };

  return (
    <Div
      bg
      bImg={bImg}
      style={responsiveStyles.backgroundImage}
    >
      <IndexGrid>
        <IndexText>
          <h1
            className="fade-in slide-up"
            style={responsiveStyles.heading}
          >
            <span className="welcome">Welcome to....</span> <br />
            <span className="fiction-hub">FICTION-HUB</span>
          </h1>
          <p
            className="fade-in slide-up"
            style={responsiveStyles.paragraph}
          >
            "Discover a vibrant platform where your creativity comes to life.
            Read, write, and share your fiction with the world."
          </p>
        </IndexText>
        <IndexButtons>
          <IndexButton
            invert
            onClick={handleWriteFiction}
            style={responsiveStyles.button}
          >
            Start Writing
          </IndexButton>
          <IndexButton
            onClick={handleExploreFictions}
            style={responsiveStyles.button}
          >
            Explore Fictions
          </IndexButton>
        </IndexButtons>
      </IndexGrid>
    </Div>
  );
}

export default IndexHeaderContainer;
