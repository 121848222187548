import React from 'react';

const EnvelopeIcon = ({ width = '24px', height = '24px', fill = '#000' }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M12 13.5l-12-9v16.5h24v-16.5l-12 9zm-10.5-10.5h21l-10.5 7.5-10.5-7.5z" />
    </svg>
);

export default EnvelopeIcon;
