import styled, { keyframes } from "styled-components";

// Animation keyframes
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const slideUp = keyframes`
  0% { transform: translateY(20px); }
  100% { transform: translateY(0); }
`;

const typing = keyframes`
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
`;

const blinkAndColorChange = keyframes`
  0% {
    opacity: 1.0;
    color: #ffc003; // Yellow
  }
  25% {
    opacity: 1.0;
    color: #135fc2; // Blue
  }
  50% {
    opacity: 1.0;
    color: #ffffff; // Orange
  }
  75% {
    opacity: 1.0;
    color: #77e610; // Green
  }
  100% {
    opacity: 1.0;
    color: #000000; // Purple
  }
`;


const blinkCaret = keyframes`
  0%, 100% {
    border-right-color: transparent;
  }
  50% {
    border-right-color: #135fd1;
  }
`;
export const Div = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.bImg});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    filter: blur(1px); /* Adds blur to the background image */
    z-index: 1;
    opacity: 0.8; /* Optional: Adjust opacity to make text more readable */
  }

  > * {
    position: relative;
    z-index: 2; /* Ensure the text and buttons are on top of the blurred background */
  }
`;

export const IndexGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
`;

export const IndexText = styled.div`
  margin-bottom: 20px;

  h1 {
    font-size: 3rem;
    animation: ${fadeIn} 2s ease-in-out, ${slideUp} 1s ease-in-out;
    
    .welcome {
      font-weight: bold;
      font-size: 2rem;

    }
    
    .fiction-hub {
      font-family: "Verdana", sans-serif;
      font-weight: bold;
      font-size: 6rem;
      animation: ${blinkAndColorChange} 8s infinite;
    }
  }

  p {
    font-size: 1.6rem;
    line-height: 1.6;
    animation: ${fadeIn} 2.5s ease-in-out, ${slideUp} 1.5s ease-in-out;
    font-weight: bold;
    color: #000000;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  }
`;

export const IndexButtons = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

export const IndexButton = styled.button`
  background-color: ${(props) => (props.invert ? "#fff" : "#000")};
  color: ${(props) => (props.invert ? "#000" : "#fff")};
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    opacity: 0.9;
  }
`;
