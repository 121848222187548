import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar.js";
import FooterContainer from "../../components/Footer/FooterContainer.jsx";
import Wrapper from "../../styles/Wrapper.js";
import MainHeading from "../../styles/MainHeading.js";
import Button from "../../styles/Button.js";
import {
  AccountInfoContainer,
  AccountInfoForm,
  FormGroup,
  FormLabel,
  FormInput,
} from "./AccountInformationStyles.js";

function AccountInformation() {
  const history = useHistory();
  const location = useLocation();
  const { selectedPlan } = location.state;

  const [accountInfo, setAccountInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAccountInfo({
      ...accountInfo,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    history.push("/billing", { selectedPlan, accountInfo });
  };

  return (
    <>
      <Navbar />
      <Wrapper py="2rem">
        <MainHeading>Account Information</MainHeading>
        <AccountInfoContainer>
          <AccountInfoForm onSubmit={handleFormSubmit}>
            <FormGroup>
              <FormLabel htmlFor="firstName">First Name</FormLabel>
              <FormInput
                type="text"
                id="firstName"
                name="firstName"
                value={accountInfo.firstName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="lastName">Last Name</FormLabel>
              <FormInput
                type="text"
                id="lastName"
                name="lastName"
                value={accountInfo.lastName}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="email">Email</FormLabel>
              <FormInput
                type="email"
                id="email"
                name="email"
                value={accountInfo.email}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <FormGroup>
              <FormLabel htmlFor="password">Password</FormLabel>
              <FormInput
                type="password"
                id="password"
                name="password"
                value={accountInfo.password}
                onChange={handleInputChange}
                required
              />
            </FormGroup>
            <Button type="submit">Proceed to Billing</Button>
          </AccountInfoForm>
        </AccountInfoContainer>
      </Wrapper>
      <FooterContainer />
    </>
  );
}

export default AccountInformation;
