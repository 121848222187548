import React from "react";
import Navbar from "../../components/Navbar/Navbar.js";
import FooterContainer from "../../components/Footer/FooterContainer.jsx";
import Wrapper from "../../styles/Wrapper.js";
import MainHeading from "../../styles/MainHeading.js";
import Button from "../../styles/Button.js";
import { Input } from "../../styles/Form/Input.js";
import categories from "../../utils/categories.js";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import {
    Div,
    Form,
    FictionDetails,
    FictionInputs,
    FictionImg,
    SelectBtn,
    ChooseImg,
    UploadBtn,
    AddChapterButton,
    ChapterContainer,
    ChapterWrapper,
    ChapterInputs,
    ChapterInput,
    DeleteChapterButton,
    ChapterTitleInputs
} from "./FictionFormStyles.js";


function EditFictionContainer({
    title,
    description,
    category,
    mdeValue,
    setMdeValue,
    preview,
    fictionImageRef,
    errors,
    handleTitleChange,
    handleDescriptionChange,
    handleCategoryChange,
    handleMdeChange,
    handleChooseImg,
    handleFormSubmit,
    chapters,
    addChapter,
    handleChapterTitleChange,
    handleChapterContentChange,
    deleteChapter
}) {
    const concatenatedContent = chapters.map((chapter) => `## ${chapter.title}\n\n${chapter.content}`).join("\n\n");

    return (
        <Div>
            <Navbar />
            <Wrapper py="1rem">
                <MainHeading>Edit Fiction</MainHeading>
                <Form onSubmit={handleFormSubmit}>
                    <FictionDetails>
                        <ChooseImg>
                            <FictionImg>
                                <img src={preview} alt="fiction" />
                            </FictionImg>
                            <UploadBtn>
                                <input
                                    type="file"
                                    onChange={handleChooseImg}
                                    ref={fictionImageRef}
                                />
                                <Button>Choose Image</Button>
                            </UploadBtn>
                        </ChooseImg>
                        <FictionInputs>
                            <div className="inputs">
                                <Input
                                    type="text"
                                    placeholder="Title"
                                    name="title"
                                    value={title}
                                    onChange={handleTitleChange}
                                />
                                <SelectBtn
                                    name="category"
                                    value={category}
                                    onChange={handleCategoryChange}
                                >
                                    {categories.map((cat, i) => (
                                        <option key={i} value={cat.name}>
                                            {cat.name}
                                        </option>
                                    ))}
                                </SelectBtn>
                            </div>
                            <div className="textarea">
                                <textarea
                                    placeholder="Description"
                                    name="description"
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                            </div>
                        </FictionInputs>
                    </FictionDetails>
                    <ChapterContainer>
                        <MainHeading>Chapters</MainHeading>
                        {chapters.map((chapter, index) => (
                            <ChapterWrapper key={index}>
                                <ChapterTitleInputs>
                                    <ChapterInput
                                        type="text"
                                        placeholder={`Chapter ${
                                            index + 1
                                        } Title`}
                                        value={chapter.title}
                                        onChange={(e) =>
                                            handleChapterTitleChange(e, index)
                                        }
                                    />
                                </ChapterTitleInputs>
                                <ChapterInputs>
                                    <SimpleMDE
                                        value={chapter.content}
                                        onChange={(value) =>
                                            handleChapterContentChange(
                                                value,
                                                index
                                            )
                                        }
                                        options={{
                                            autofocus: true,
                                            spellChecker: false,
                                        }}
                                    />
                                    <DeleteChapterButton
                                        type="button"
                                        onClick={() => deleteChapter(index)}
                                    >
                                        Delete Chapter
                                    </DeleteChapterButton>
                                </ChapterInputs>
                            </ChapterWrapper>
                        ))}
                        <AddChapterButton type="button" onClick={addChapter}>
                            Add Chapter
                        </AddChapterButton>
                    </ChapterContainer>
                    {/* SimpleMDE editor */}
                    <MainHeading>CHAPTER PREVIEW</MainHeading>
                    {/* Set mdeValue as concatenated content */}
                    <SimpleMDE onChange={setMdeValue} value={mdeValue} />
                    <Button type="submit">Submit Fiction</Button>
                </Form>
            </Wrapper>
            <FooterContainer />
        </Div>
    );
}

export default EditFictionContainer;
