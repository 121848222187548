import styled from "styled-components";
import { colors } from "../../styles/variables.js";
import { primaryFont } from "../../styles/typography.js";

export const Div = styled.div`
    min-height: 100vh;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
`;

export const Form = styled.form`
    margin-top: 2rem;
`;

export const ChooseImg = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const FictionDetails = styled.div`
    display: flex;
    margin-bottom: 2rem;

    @media (max-width: 700px) {
        flex-direction: column;
        margin-bottom: 1rem;
    }
`;

export const FictionInputs = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 1rem;

    @media (max-width: 700px) {
        margin-left: 0;
        margin-top: 2.4rem;
    }

    .inputs {
        width: 100%;
        display: flex;
        margin-bottom: 0.5rem;

        @media (max-width: 430px) {
            margin-bottom: 1rem;
            flex-direction: column-reverse;
        }

        input, select {
            flex: 1;
            width: 100%;
            margin-right: 0.5rem;
        }
    }

    .textarea {
        flex: 1;

        textarea {
            resize: none;
            height: 100%;
            width: 100%;
            font-size: 1rem;
            font-weight: 500;
            font-family: inherit;
            padding: 0.5rem 0.7rem;
            border: 0.1rem solid ${colors.inputBorder};
            border-radius: 0.3rem;
            color: ${colors.inputText};

            @media (max-width: 700px) {
                height: 6rem;
            }

            &:active,
            &:focus {
                border: 0.1rem solid ${colors.inputBorderActive};
                outline: none;
            }

            &::placeholder {
                color: ${colors.inputPlaceholder};
                font-family: inherit;
            }
        }
    }
`;

export const FictionImg = styled.div`
    width: 7rem;
    height: 10.5rem;
    overflow: hidden;
    border-radius: 8px;
    display: block;

    img {
        object-position: 50% 50%;
        object-fit: cover;
        display: block;
        width: 100%;
        height: 100%;
    }
`;

export const UploadBtn = styled.div`
    position: relative;
    overflow: hidden;
    display: inline-block;
    cursor: pointer;
    margin-top: 0.5rem;

    input {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        font-size: 2rem;
        opacity: 0;
    }
`;

export const SelectBtn = styled.select`
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    color: blue;
    border: 1px solid ${colors.border};
    border-radius: 0.3rem;

    &:focus {
        outline: none;
        border-color: ${colors.primary};
    }
`;

export const DeleteChapterButton = styled.button`
    background-color: ${colors.btnBgLight};
    color: red;
    border: none;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    font-family: ${primaryFont};
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
        background-color: ${colors.primaryHover};
    }
`;

export const AddChapterButton = styled.button`
    background-color: blue;
    color: white;
    border: none;
    border-radius: 0.3rem;
    padding: 0.5rem 1rem;
    font-family: ${primaryFont};
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
        background-color: ${colors.primaryHover};
    }
`;

export const ChapterContainer = styled.div`
    margin-top: 2rem;
`;

export const ChapterWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    margin-bottom: 1rem;
`;

export const ChapterInputs = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`;

export const ChapterTitleInputs = styled.div`
    width: 100%;
    margin-bottom: 1rem;
`;

export const ChapterInput = styled.input`
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid ${colors.border};
    border-radius: 0.3rem;

    &:focus {
        outline: none;
        border-color: ${colors.primary};
    }
`;

export const ChapterTextArea = styled.textarea`
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid ${colors.border};
    border-radius: 0.3rem;
    resize: vertical;

    &:focus {
        outline: none;
        border-color: ${colors.primary};
    }
`;

export const BoldChapterTitle = styled.span`
    font-weight: bold;
`;
