import styled from "styled-components";
import { colors } from "../../styles/variables.js";
import Wrapper from "../../styles/Wrapper.js";

export const PlansContainer = styled(Wrapper)`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 2rem;
`;

export const PlanCard = styled.div`
  background: ${(props) => props.backgroundColor || colors.cardBg};
  border: ${(props) => (props.selected ? `2px solid ${colors.primary}` : "1px solid #ccc")};
  border-radius: 10px;
  padding: 1.5rem;
  width: 300px;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: ${(props) => (props.selected ? `0 4px 12px rgba(0, 0, 0, 0.2)` : "none")};
  color: #fff;

  &:hover {
    transform: scale(1.05);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
`;


export const PlanTitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: 'Arial', sans-serif;
  color: ${(props) => props.color};
`;

export const PlanPrice = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1rem;
  font-weight: bold;
  color: #6aff00;
  font-family: 'Arial', sans-serif;
`;

export const PlanDetails = styled.div`
  margin-bottom: 1.5rem;
`;

export const PlanFeature = styled.p`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: #fff;
  font-family: 'Arial', sans-serif;
`;

export const PlanPopularBadge = styled.div`
  background: ${colors.primary};
  color: #fff;
  padding: 0.3rem 0.6rem;
  border-radius: 20px;
  font-size: 0.8rem;
  position: absolute;
  top: -10px;
  right: -10px;
`;

export const PlanComparison = styled.div`
  margin: 2rem 0;
  overflow-x: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    font-family: 'Arial', sans-serif;
  }

  th, td {
    border: 1px solid #ccc;
    padding: 0.75rem;
    text-align: center;
  }

  th {
    background: ${colors.cardBg};
    color: #000000;
  }

  td {
    background: ${colors.cardLightBg};
    color: #000;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  bottom: -2rem;
  left: 50%;
  transform: translateX(-50%);
  background: ${colors.tooltipBg};
  color: #0048ff;
  padding: 0.5rem;
  border-radius: 4px;
  font-size: 0.8rem;
  display: none;

  ${PlanCard}:hover & {
    display: block;
  }
`;
