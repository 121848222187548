import React from 'react';
import { useLocation } from 'react-router-dom';
import PrivateMessaging from '../../components/PrivateMessaging';
import styled from 'styled-components';
import Navbar from '../../components/Navbar/Navbar';
import FooterContainer from '../../components/Footer/FooterContainer';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function MessagesPage() {
    let query = useQuery();
    const userId = query.get('user');
    const otherUserId = query.get('otherUser');

    return (
        <Container>
            <Navbar />
            <Content>
                <Title>Send Private Message</Title>
                <PrivateMessaging userId={userId} otherUserId={otherUserId} />
            </Content>
            <FooterContainer />
        </Container>
    );
}

export default MessagesPage;

// Styled components
const Container = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
`;

const Content = styled.div`
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Title = styled.h1`
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
`;
