import styled from "styled-components";

export const SubscriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
`;

export const SubscriptionCard = styled.div`
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  width: 300px;
`;

export const SubscriptionTitle = styled.h2`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  color: #333;
`;

export const SubscriptionPrice = styled.p`
  margin-bottom: 1rem;
  font-size: 1.25rem;
  color: #333;
`;

export const SubscriptionDetails = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 1.5rem;
`;

export const SubscriptionFeature = styled.li`
  margin-bottom: 0.5rem;
  font-size: 1rem;
  color: #666;
`;
