import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useHistory, Link } from "react-router-dom"; // Import Link from react-router-dom
import { useDispatch } from "react-redux";
import jwt_decode from "jwt-decode";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyles from "./styles/globalStyles.js";
import IndexPage from "./pages/IndexPage/IndexPage.js";
import RegisterPage from "./pages/RegisterPage/RegisterPage.js";
import LoginPage from "./pages/LoginPage/LoginPage.js";
import ForgotPasswordPage from "./pages/ForgotPasswordPage/ForgotPasswordPage.js";
import ResetPasswordPage from "./pages/ResetPasswordPage/ResetPasswordPage.js";
import Homepage from "./pages/Homepage/Homepage.js";
import ProfilePage from "./pages/ProfilePage/ProfilePage.js";
import EditProfile from "./pages/EditProfile/EditProfile.js";
import FanficPage from "./pages/FanficPage/FanficPage.js";
import ChaptersPage from "./pages/Chapters/ChaptersPage.js"; // Correct import
import CategoriesPage from "./pages/CategoriesPage/CategoriesPage.js";
import CategoryResultsPage from "./pages/CategoryResultsPage/CategoryResultsPage.js";
import SearchPage from "./pages/SearchPage/SearchPage.js";
import ConnectionsPage from "./pages/ConnectionsPage/ConnectionsPage.js";
import CreateFictionForm from "./pages/FictionForm/CreateFictionForm.js";
import EditFictionForm from "./pages/FictionForm/EditFictionForm.js";
import PrivateRoute from "./utils/PrivateRoute.js";
import Loader from "./components/Loader/Loader.js";
import setAuthToken from "./redux/utils/setAuthToken.js";
import { logoutUser, setCurrentUser } from "./redux/actions/authActions.js";
import axios from "axios";
import keys from "./keys.js";
import TryPremium from "./pages/TryPremium/TryPremium.jsx";
import Subscribe from "./pages/SubscribePage/Subscribe.jsx"; // Add this if you have a subscription page
import SubscriptionPlanSelection from "./pages/SubscribePage/SubscriptionPlanSelection.jsx";
import AccountInformation from "./pages/SubscribePage/AccountInformation.jsx";
import Payment from "./pages/PaymentPage/Payment.jsx"; 
import BillingInformation from "./pages/SubscribePage/BillingInformation.jsx"; 


import MessagesPage from "./pages/MessagesPage/MessagesPage.js"; // Import the new MessagesPage

axios.defaults.baseURL = keys.apiEndpoint;

function App() {
    const dispatch = useDispatch();
    const history = useHistory();

    // Your existing code...

    useEffect(() => {
        axios
            .get("/api/test")
            .then((res) => console.log({ data: res.data }))
            .catch((err) => console.log({ err: err.response }));
    }, []);

    return (
        <>
            <ToastContainer />
            <GlobalStyles />
            <Router>
                <Switch>
                    <Route exact path="/">
                        <IndexPage />
                    </Route>
                    <Route path="/messages" component={MessagesPage} />
                    <Route path="/loader">
                        <Loader />
                    </Route>
                    <Route path="/register">
                        <RegisterPage />
                    </Route>
                    <Route path="/login">
                        <LoginPage />
                    </Route>
                    <Route path="/forgot-password">
                        <ForgotPasswordPage />
                    </Route>
                    <Route path="/reset/:resetId">
                        <ResetPasswordPage />
                    </Route>
                    <PrivateRoute path="/home" component={Homepage} />
                    <PrivateRoute path="/profile/:userId/edit" component={EditProfile} />
                    <Route exact path="/profile/:userId">
                        <ProfilePage />
                    </Route>
                    <Route exact path="/fiction/:fictionId">
                        <FanficPage />
                    </Route>
                    <PrivateRoute path="/fiction/:fictionId/edit" component={EditFictionForm} />
                    <Route exact path="/categories">
                        <CategoriesPage />
                    </Route>
                    <Route path="/categories/:category">
                        <CategoryResultsPage />
                    </Route>
                    <Route path="/search">
                        <SearchPage />
                    </Route>
                    <PrivateRoute path="/profile/:userId/connections" component={ConnectionsPage} />
                    <Route path="/connections">
                        <ConnectionsPage />
                    </Route>
                    <PrivateRoute path="/create-fiction" component={CreateFictionForm} />
                    <Route path="/unauthorized">
                        <h1>Unauthorized</h1>
                    </Route>
                    <Route path="/Chapters/:fictionId">
                        <ChaptersPage />
                    </Route>
                    <Route path="/try-premium" component={TryPremium} />
                    <Route path="/subscribe" component={Subscribe} />
                    <Route path="/plans" component={SubscriptionPlanSelection} />
                    <Route path="/account" component={AccountInformation} />
                    <Route path="/billing" component={BillingInformation} />
                    <PrivateRoute path="/messages" component={MessagesPage} />
                    <Route path="/payment" component={Payment} />
                    <Route path="/" component={SubscriptionPlanSelection} />
                </Switch>
            </Router>
        </>
    );
}

export default App;
