import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import EditFictionContainer from "./EditFictionContainer.jsx";

function EditFictionForm() {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");
    const [mdeValue, setMdeValue] = useState("");
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState("");
    const [imageChanged, setImageChanged] = useState(false);
    const { fictionId } = useParams();
    const [chapters, setChapters] = useState([]);

    useEffect(() => {
        axios.get(`/api/fictions/${fictionId}`).then((res) => {
            let fic = res.data.fiction;
            setTitle(fic.title);
            setDescription(fic.description);
            setCategory(fic.category);
            setMdeValue(fic.body);
            setPreview(fic.image);
            setFile(fic.image);
            setChapters(fic.chapters || []);
        });
    }, [fictionId]);

    // handlers
    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);
    const handleCategoryChange = (e) => setCategory(e.target.value);
    const handleMdeChange = (val) => setMdeValue(val);

    const handleChooseImg = (e) => {
        let selectedFile = e.target.files[0];
        let previewImage = URL.createObjectURL(selectedFile);
        setFile(selectedFile);
        setPreview(previewImage);
        setImageChanged(true);
    };

    const handleChapterTitleChange = (e, index) => {
        const updatedChapters = [...chapters];
        updatedChapters[index].title = e.target.value;
        setChapters(updatedChapters);
    };

    const handleChapterContentChange = (val, index) => {
        const updatedChapters = [...chapters];
        updatedChapters[index].content = val;
        setChapters(updatedChapters);
    };

    const addChapter = () => {
        setChapters([...chapters, { title: "", content: "" }]);
    };

    const deleteChapter = (index) => {
        const updatedChapters = [...chapters];
        updatedChapters.splice(index, 1);
        setChapters(updatedChapters);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();

        formData.append("imageChanged", imageChanged);
        formData.append("selectedFile", file);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("category", category);
        formData.append("body", mdeValue);
        formData.append("chapters", JSON.stringify(chapters));

        axios
            .patch(`/api/fictions/${fictionId}`, formData)
            .then((res) => {
                toast.success("Fiction Updated Successfully");
            })
            .catch((err) => {
                console.error(err);
                toast.error("Failed to update fiction");
            });
    };

    return (
        <EditFictionContainer
            title={title}
            description={description}
            category={category}
            mdeValue={mdeValue}
            setMdeValue={setMdeValue}
            preview={preview}
            handleTitleChange={handleTitleChange}
            handleDescriptionChange={handleDescriptionChange}
            handleCategoryChange={handleCategoryChange}
            handleMdeChange={handleMdeChange}
            handleChooseImg={handleChooseImg}
            handleFormSubmit={handleFormSubmit}
            chapters={chapters}
            addChapter={addChapter}
            handleChapterTitleChange={handleChapterTitleChange}
            handleChapterContentChange={handleChapterContentChange}
            deleteChapter={deleteChapter}
        />
    );
}

export default EditFictionForm;
