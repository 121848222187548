import React from "react";
import SimpleMDE from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";
import {
    ChapterContainer,
    ChapterWrapper,
    ChapterInputs,
    ChapterInput,
    DeleteChapterButton,
    AddChapterButton
} from "./FictionFormStyles.js";
import MainHeading from "../../styles/MainHeading.js";

function CreateChaptersForm({ chapters, addChapter, handleChapterTitleChange, handleChapterContentChange, deleteChapter }) {
    return (
        <ChapterContainer>
            <MainHeading>Chapters</MainHeading>
            {chapters.map((chapter, index) => (
                <ChapterWrapper key={index}>
                    <ChapterInputs>
                        <ChapterInput
                            type="text"
                            placeholder={`Chapter ${index + 1} Title`}
                            value={chapter.title}
                            onChange={(e) => handleChapterTitleChange(e, index)}
                        />
                        <SimpleMDE
                            value={chapter.content}
                            onChange={(value) => handleChapterContentChange(value, index)}
                            options={{
                                autofocus: true,
                                spellChecker: false,
                            }}
                        />
                        <DeleteChapterButton
                            type="button"
                            onClick={() => deleteChapter(index)}
                        >
                            Delete Chapter
                        </DeleteChapterButton>
                    </ChapterInputs>
                </ChapterWrapper>
            ))}
            <AddChapterButton type="button" onClick={addChapter}>
                Add Chapter
            </AddChapterButton>
        </ChapterContainer>
    );
}

export default CreateChaptersForm;
