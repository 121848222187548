import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SimpleMDE from 'react-simplemde-editor'; // Import from react-simplemde-editor
import "easymde/dist/easymde.min.css"; // Import the SimpleMDE CSS
import styled from 'styled-components';

function PrivateMessaging({ userId, otherUserId }) {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        // Fetch messages from the server
        axios.get(`/api/messages?user=${userId}&otherUser=${otherUserId}`)
            .then(response => setMessages(response.data))
            .catch(error => console.error(error));

        // Scroll to the bottom of the messages
        scrollToBottom();
    }, [userId, otherUserId]);

    const handleSendMessage = () => {
        if (newMessage.trim() === '') return;

        const message = {
            sender: userId,
            receiver: otherUserId,
            text: newMessage,
            timestamp: new Date().toISOString()
        };

        axios.post('/api/messages', message)
            .then(response => {
                setMessages([...messages, response.data]);
                setNewMessage('');
                setIsTyping(false);
                scrollToBottom();
            })
            .catch(error => console.error(error));
    };

    const handleInputChange = (value) => {
        setNewMessage(value);
        setIsTyping(true);
    };

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <MessagingContainer>
            <MessagesList>
                {messages.map((message, index) => (
                    <Message key={index} isOwnMessage={message.sender === userId}>
                        <MessageText>{message.text}</MessageText>
                        <Timestamp>{new Date(message.timestamp).toLocaleTimeString()}</Timestamp>
                    </Message>
                ))}
                <div ref={messagesEndRef} />
            </MessagesList>
            {isTyping && <TypingIndicator>Typing...</TypingIndicator>}
            <MessageInputContainer>
                <StyledSimpleMDE
                    value={newMessage}
                    onChange={handleInputChange}
                    options={{
                        placeholder: 'Type a message...',
                        toolbar: false,
                        status: false
                    }}
                />
                <SendButton onClick={handleSendMessage}>Send</SendButton>
            </MessageInputContainer>
        </MessagingContainer>
    );
}

export default PrivateMessaging;

// Styled components
const MessagingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 800px;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #fff;
`;

const MessagesList = styled.div`
    flex: 1;
    overflow-y: auto;
    max-height: 400px;
    margin-bottom: 20px;
`;

const Message = styled.div`
    margin: 10px 0;
    padding: 10px;
    border-radius: 8px;
    background-color: ${(props) => (props.isOwnMessage ? '#DCF8C6' : '#F1F0F0')};
    align-self: ${(props) => (props.isOwnMessage ? 'flex-end' : 'flex-start')};
    max-width: 80%;
    position: relative;
`;

const MessageText = styled.div`
    word-wrap: break-word;
`;

const Timestamp = styled.div`
    font-size: 0.75rem;
    color: #999;
    position: absolute;
    bottom: 2px;
    right: 10px;
`;

const TypingIndicator = styled.div`
    font-size: 0.85rem;
    color: #999;
    margin-bottom: 10px;
`;

const MessageInputContainer = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const StyledSimpleMDE = styled(SimpleMDE)`
    flex: 1;
    .editor-toolbar {
        display: none;
    }
`;

const SendButton = styled.button`
    padding: 10px 20px;
    background-color: #007BFF;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-left: 10px;

    &:hover {
        background-color: #0056b3;
    }
`;
