import styled from "styled-components";
import { colors } from "./variables.js";

const Section = styled.section`
  position: relative;
  padding: 1.8rem 0;
  margin: 2rem 0;
  background: ${(props) =>
    props.bgImage
      ? `url(${props.bgImage}) no-repeat center center/cover`
      : props.bg
      ? colors.sectionBg
      : "#fff"};
  background-size: cover;
  filter: blur(25px)

  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: ${(props) =>
      props.bgImage
        ? `url(${props.bgImage}) no-repeat center center/cover`
        : "none"};
    background-size: cover;
    filter: blur(25px);
    z-index: -1;
  }
`;

export default Section;
