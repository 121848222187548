import styled from "styled-components";

export const PaymentContainer = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
`;

export const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
`;

export const PaymentMethods = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 1.5rem 0;
`;

export const PaymentMethod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 1rem;
  border: ${(props) => (props.selected ? "2px solid #000" : "2px solid transparent")};
  border-radius: 10px;
  transition: border-color 0.3s;

  &:hover {
    border-color: #000;
  }

  img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }
`;

export const PaymentForm = styled.form`
  margin-top: 2rem;
`;
