import React, { useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import CreateFictionContainer from "./CreateFictionContainer.jsx";
import categories from "../../utils/categories.js";
import previewPlaceholder from "../../images/default-fiction-image.svg";

function CreateFictionForm({ history }) {
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState(categories[0].name);
    const [mdeValue, setMdeValue] = useState("");
    const [file, setFile] = useState(null);
    const [preview, setPreview] = useState(previewPlaceholder);
    const [errors, setErrors] = useState({
        title: "",
        description: "",
        category: "",
        body: ""
    });
    const [chapters, setChapters] = useState([]);
    const fictionImageRef = useRef();

    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);
    const handleCategoryChange = (e) => setCategory(e.target.value);
    const handleMdeChange = (val) => setMdeValue(val);
    const handleChooseImg = (e) => {
        let selectedFile = e.target.files[0];
        let previewImage = URL.createObjectURL(selectedFile);
        setFile(selectedFile);
        setPreview(previewImage);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();

        let formData = new FormData();
        formData.append("selectedFile", file);
        formData.append("title", title);
        formData.append("description", description);
        formData.append("category", category);
        formData.append("content", mdeValue);

        axios.post("/api/fictions/", formData)
            .then((res) => {
                const fictionId = res.data._id;

                // Submit chapters
                const chapterPromises = chapters.map((chapter) => {
                    return axios.post(`/api/fictions/${fictionId}/chapters`, {
                        title: chapter.title,
                        content: chapter.content
                    });
                });

                return Promise.all(chapterPromises);
            })
            .then(() => {
                setTitle("");
                setDescription("");
                setCategory(categories[0].name);
                setMdeValue("");
                setFile(null);
                setPreview(previewPlaceholder);
                fictionImageRef.current.value = "";
                setChapters([]);
                toast.success("Fiction & Chapters Created Successfully.");
                history.push("/fictions"); // Redirect to the fictions page
            })
            .catch((err) => {
                const { data } = err.response;
                const { inputs, errors } = data;
                setTitle(inputs.title);
                setDescription(inputs.description);
                setCategory(inputs.category);
                setErrors({
                    title: "",
                    description: "",
                    category: "",
                    body: "",
                    ...errors
                });
            });
    };

    const addChapter = () => {
        setChapters([...chapters, { title: "", content: "" }]);
    };

    const handleChapterTitleChange = (e, index) => {
        const updatedChapters = chapters.map((chapter, i) =>
            i === index ? { ...chapter, title: e.target.value } : chapter
        );
        setChapters(updatedChapters);
    };

    const handleChapterContentChange = (content, index) => {
        const updatedChapters = chapters.map((chapter, i) =>
            i === index ? { ...chapter, content: content } : chapter
        );
        setChapters(updatedChapters);
    };

    const deleteChapter = (index) => {
        setChapters(chapters.filter((_, i) => i !== index));
    };

    return (
        <CreateFictionContainer
            title={title}
            description={description}
            category={category}
            mdeValue={mdeValue}
            setMdeValue={setMdeValue}
            preview={preview}
            fictionImageRef={fictionImageRef}
            errors={errors}
            handleTitleChange={handleTitleChange}
            handleDescriptionChange={handleDescriptionChange}
            handleCategoryChange={handleCategoryChange}
            handleMdeChange={handleMdeChange}
            handleChooseImg={handleChooseImg}
            handleFormSubmit={handleFormSubmit}
            chapters={chapters}
            addChapter={addChapter}
            handleChapterTitleChange={handleChapterTitleChange}
            handleChapterContentChange={handleChapterContentChange}
            deleteChapter={deleteChapter}
        />
    );
}

export default CreateFictionForm;
