import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar.js";
import FooterContainer from "../../components/Footer/FooterContainer.jsx";
import Wrapper from "../../styles/Wrapper.js";
import MainHeading from "../../styles/MainHeading.js";
import {
  PaymentContainer,
  FormGroup,
  Label,
  Input,
  PaymentMethods,
  PaymentMethod,
  PaymentForm,
} from "./PaymentStyles.js";
import Button from "../../styles/Button.js";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

import googlepayIcon from "../../assets/icons/googlepay.png";
import paypalIcon from "../../assets/icons/paypal.png";

const paymentMethods = [
  { name: "GooglePay", icon: googlepayIcon },
  { name: "PayPal", icon: paypalIcon },
];

function Payment() {
  const location = useLocation();
  const history = useHistory();
  const { selectedPlan, accountInfo } = location.state;

  const [nameOnCard, setNameOnCard] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const handleFormSubmit = (e) => {
    e.preventDefault();
    history.push("/confirmation", { selectedPlan, accountInfo });
  };

  const handlePaypalApprove = (data, actions) => {
    return actions.order.capture().then((details) => {
      history.push("/confirmation", { selectedPlan, accountInfo, paymentDetails: details });
    });
  };

  const renderPaymentForm = () => {
    switch (selectedPaymentMethod) {
      case "GooglePay":
        return (
          <p>Google Pay is selected. Proceed with Google Pay integration.</p>
        );
      case "CreditCard":
        return (
          <PaymentForm onSubmit={handleFormSubmit}>
            <FormGroup>
              <Label htmlFor="nameOnCard">Name on Card</Label>
              <Input
                type="text"
                id="nameOnCard"
                value={nameOnCard}
                onChange={(e) => setNameOnCard(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="cardNumber">Card Number</Label>
              <Input
                type="text"
                id="cardNumber"
                value={cardNumber}
                onChange={(e) => setCardNumber(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="expiryDate">Expiry Date</Label>
              <Input
                type="text"
                id="expiryDate"
                value={expiryDate}
                onChange={(e) => setExpiryDate(e.target.value)}
                placeholder="MM/YY"
                required
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="cvv">CVV</Label>
              <Input
                type="text"
                id="cvv"
                value={cvv}
                onChange={(e) => setCvv(e.target.value)}
                required
              />
            </FormGroup>
            <Button type="submit">Complete Subscription</Button>
          </PaymentForm>
        );
      case "PayPal":
        return (
          <PayPalScriptProvider options={{ "client-id": "ASn_n7V5Kj9wJfPzgTcyvJjFG2R2RS23uMnMrpJExExcx3cERTu-LAQld4l17gJK6gqzdRo4XFYBZdgS" }}>
            <PayPalButtons
              createOrder={(data, actions) => {
                return actions.order.create({
                  purchase_units: [{
                    amount: {
                      value: selectedPlan.price, // Use the plan price here
                    },
                  }],
                });
              }}
              onApprove={handlePaypalApprove}
            />
          </PayPalScriptProvider>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Navbar />
      <Wrapper py="2rem">
        <MainHeading>Payment Information</MainHeading>
        <PaymentContainer>
          <p><strong>Selected Plan:</strong> {selectedPlan.title}</p>
          <p><strong>Price:</strong> {selectedPlan.price}</p>
          <p><strong>First Name:</strong> {accountInfo.firstName}</p>
          <p><strong>Last Name:</strong> {accountInfo.lastName}</p>
          <p><strong>Email:</strong> {accountInfo.email}</p>
          <PaymentMethods>
            {paymentMethods.map((method, index) => (
              <PaymentMethod
                key={index}
                selected={selectedPaymentMethod === method.name}
                onClick={() => setSelectedPaymentMethod(method.name)}
              >
                <img src={method.icon} alt={`${method.name} icon`} />
                <span>{method.name}</span>
              </PaymentMethod>
            ))}
          </PaymentMethods>
          {selectedPaymentMethod && renderPaymentForm()}
        </PaymentContainer>
      </Wrapper>
      <FooterContainer />
    </>
  );
}

export default Payment;
