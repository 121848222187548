import React from "react";
import { Link } from "react-router-dom";
import Navbar from "../../components/Navbar/Navbar.js";
import FooterContainer from "../../components/Footer/FooterContainer.jsx";
import Wrapper from "../../styles/Wrapper.js";
import MainHeading from "../../styles/MainHeading.js";
import Button from "../../styles/Button.js";
import {
  SubscriptionContainer,
  SubscriptionCard,
  SubscriptionDetails,
  SubscriptionTitle,
  SubscriptionPrice,
  SubscriptionFeature,
} from "./Subscribe.js";

function Subscribe() {
  return (
    <>
      <Navbar />
      <Wrapper py="2rem">
        <MainHeading>Premium Subscription</MainHeading>
        <SubscriptionContainer>
          <SubscriptionCard>
            <SubscriptionTitle>Premium Plan</SubscriptionTitle>
            <SubscriptionPrice>$9.99 / month</SubscriptionPrice>
            <SubscriptionDetails>
              <SubscriptionFeature>Ad-free experience</SubscriptionFeature>
              <SubscriptionFeature>Access to exclusive content</SubscriptionFeature>
              <SubscriptionFeature>Priority support</SubscriptionFeature>
              <SubscriptionFeature>Early access to new features</SubscriptionFeature>
            </SubscriptionDetails>
            <Link to="/plans">
              <Button>Subscribe Now</Button>
            </Link>
          </SubscriptionCard>
        </SubscriptionContainer>
      </Wrapper>
      <FooterContainer />
    </>
  );
}

export default Subscribe;
